import React from 'react';
import cn from 'classnames';

import type { Pageview } from '../../services/Pageview';
import { StaticImage } from '../Image';
import MetaTags from '../MetaTags';
import { useOptionalTranslate } from '../Translate';

import PageLayout from './Page';

type Props = {
  title: string;
  subtitle?: string;
  className?: string;
  heroImg?: React.ReactNode;
  children: React.ReactNode;
  pageview?: Pageview;
};

export default function StaticPage(props: Props) {
  const tTitle = useOptionalTranslate(props.title);
  const tSubtitle = useOptionalTranslate(props.subtitle);

  return (
    <PageLayout maySkipBlocker pageview={props.pageview}>
      <MetaTags title={tTitle} />
      <div className="hero" data-size="xs">
        {props.heroImg ? props.heroImg : <StaticImage alt="" className="hero__bg" name="page" />}

        <div className="hero--container container flex-column align-items-center">
          <h1 className="hero--headline">{tTitle}</h1>
          {tSubtitle && tSubtitle !== '' ? <h2 className="hero--subheadline">{tSubtitle}</h2> : null}
        </div>
      </div>
      <main className={cn('main-container', props.className)}>{props.children}</main>
    </PageLayout>
  );
}
