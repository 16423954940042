import React from 'react';
import type { PageProps } from 'gatsby';

import { StaticImage } from '../views/Image';
import StaticPageLayout from '../views/Layout/StaticPage';

import styles from './staticPage.module.scss';

interface PageContext {
  language: string;
  name: string;
  content: string;
}

export default function CorporatePage({ pageContext }: PageProps<UnsafeAny, PageContext>) {
  return (
    <StaticPageLayout
      className="my-5"
      heroImg={<StaticImage alt="" className="hero__bg" name="hp_hero_grc" />}
      title={pageContext.name}
    >
      <div className={styles.container} dangerouslySetInnerHTML={{ __html: pageContext.content }} />
    </StaticPageLayout>
  );
}
